.wrapper {
  border: 1px solid var(--grey-200);
  border-radius: var(--border-radius-lg);
  margin: 0 auto;
  max-width: 600px;
  
  header {
    padding: 19px 20px;
    margin: 0;
  }
}

.link {
  background-image: url('../../../../assets/icons/chevron-right.svg');
  background-size: 7px 12px;
  background-position: calc(100% - 20px) center;
  background-repeat: no-repeat;
  
  &:hover {
    background-color: var(--grey-25);
  }
  
  a {
    font-size: var(--font-14);
    font-weight: 500;
    padding: 19px 0;
    padding-right: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: var(--grey-900);

    &:hover {
      text-decoration: none;
    }
  }

  &:not(:last-child) {
    border-bottom: 1px solid var(--grey-200);
  }

  &:last-child {
    border-radius: 0 0 var(--border-radius-lg) var(--border-radius-lg);
  }
}

.linkIcon {
  margin-right: 20px;
  display: block;
  width: 20px;
  height: 20px;

  svg {
    object-fit: cover;
  }
}

.badge {
  font-size: var(--font-8);
  text-transform: uppercase;
  font-weight: 700;
  background-color: hsl(269, 100%, 95%);
  color: hsl(272, 67%, 39%);
  padding: 4px;
  border-radius: 4px;
}

.linksWrapper {
  padding-left: 20px;
}