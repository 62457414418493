.fileSelect {
  display: flex;
  justify-content: space-between;

  div {
    flex: 1;
  }
}

.fakeLabel {
  margin: 0;
  font-size: var(--font-14);
  font-weight: 500;
  margin-bottom: 8px;
}

.filenameWrapper {
  display: block;
  width: 90%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}