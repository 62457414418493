.highlight {
  display: inline-block;
  animation: shake 0.2s ease-in-out infinite;
}

@keyframes shake {
  25% {
    transform: translateX(+3px)
  }
  75% {
    transform: translateX(-3px)
  }
}