button.button {
  cursor: pointer;

  &.primary {
    --background: var(--primary-color);
    --text: var(--white);
    --hover-background: var(--primary-hover-color);
    --active-background: var(--primary-active-color);
    --disabled-color: var(--primary-disabled-color);
    --border: 0;
  }

  &.secondary {
    --background: var(--white);
    --text: var(--primary-text-color);
    --hover-background: var(--secondary-hover-color);
    --active-background: var(--secondary-active-color);
    --disabled-color: var(--secondary-disabled-color);
    --border: 1px solid var(--input-border-color);
  }

  &.tertiary {
    --background: var(--primary-50);
    --text: var(--primary-500);
    --hover-background: var(--primary-100);
    --active-background: var(--primary-200);
    --disabled-color: var(--secondary-disabled-color);
    --border: 0;
  }

  &.destructive {
    --background: var(--negative-600);
    --text: var(--white);
    --hover-background: var(--negative-700);
    --active-background: var(--negative-700);
    --disabled-color: var(--secondary-disabled-color);
    --border: 0;
  }

  align-items: center;
  font-size: var(--font-14);
  font-weight: 600;
  color: var(--text);
  background-color: var(--background);
  outline: none;
  border: none;
  border: var(--border);
  border-radius: var(--border-radius);
  padding: var(--padding);
  white-space: nowrap;
  align-self: center;

  &:hover {
    background-color: var(--hover-background);
  }

  &:not(:disabled):active {
    background-color: var(--active-background);
  }

  &:disabled {
    color: var(--disabled-color);
    background-color: var(--grey-50);
  }

  &:focus-visible {
    box-shadow: 0px 0px 0px 2px var(--white), 0px 0px 0px 4px var(--button-focus-halo-color);
  }

  &.standard {
    --padding: 11px 16px;
  }

  &.large {
    --padding: 13px 30px;
  }

  &.text {
    --padding: 0;
    font-weight: bold;
    color: var(--primary-500);
    background-color: transparent;
    border-radius: 0;
    font-size: inherit;

    &.small {
      font-size: var(--font-14);
    }

    &:hover {
      text-decoration: underline;
    }

    &:active {
      background-color: transparent;
    }

    &:focus {
      box-shadow: none;
    }

    &:disabled {
      cursor: not-allowed;
    }
  }

  .icon {
    display: inline-block;
    width: 25px;
    height: 25px;
    margin-right: 5px;

    svg {
      object-fit: contain;
    }
  }

  .loading {
    display: inline-flex;
    align-items: center;
    margin-right: 9px;
  }
}

.fullWidth {
  display: flex;
  width: 100%;
}

.inline {
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
}

.centerText {
  justify-content: center;
}

.disableClick {
  pointer-events: none;
}
