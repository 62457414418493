.loginCenterContent {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loginFooter {
  position: absolute;
  bottom: 10px;
  width: 100%;
  text-align: center;
  font-size: var(--font-12);
  color: var(--grey-500);
}

.version {
  font-size: var(--font-13);
  color: var(--grey-900);
  position: absolute;
  bottom: 0;
  right: 10px;

  span {
    cursor: pointer;
  }
}

.logo {
  position: absolute;
  top: 19px;
  left: 19px;
  display: block;
  width: 300px;
  height: 36px;
  background-image: url('/assets/partner-portal-logo.svg');
  background-repeat: no-repeat;
  background-size: contain;
  background-position-y: center;
}