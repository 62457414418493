@import '../../../styles/mixins';

.header {
  display: flex;
  height: 64px;
  border-bottom: 1px solid var(--grey-200);
  align-items: center;
  justify-content: space-between;
  padding-left: 1em;
  padding-right: 1em;
  z-index: 0;
}

.subHeader {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-bottom: 48px;
  padding-top: 5px;
  padding-right: 2em;
  font-weight: 500;
}

.nav {
  position: relative;
  flex: 2;

  ul {
    display: flex;
    justify-content: space-between;
    flex-direction: column;

    @include respond(desktop) {
      flex-direction: row;
    }
  }

  li {
    background-color: var(--white);
    z-index: 1;
    padding: 0 0.8em;
    margin: 0 1.5em;
  }

  a {
    display: flex;
    color: var(--primary-text-color);
    font-size: var(--font-14);
    white-space: nowrap;
    // pointer-events: none;

    span {
      line-height: 1;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      width: 20px;
      height: 20px;
      background-color: lightgray;
      color: #202020;
      border-radius: 10px;
      margin-right: 8px;
    }

    &:hover {
      text-decoration: none;
      color: var(--primary-color);

      span {
        background-color: var(--primary-color);
        color: var(--white);
      }
    }
  }

  a.active {
    color: var(--primary-color);

    span {
      background-color: var(--primary-color);
      color: var(--white);
    }
  }
}

.line {
  position: relative;
  width: 90%;
  height: 1px;
  background-color: #E7E7E7;
  top: 12px;
  margin: 0 auto;
}

.contentWrapper {
  max-width: 900px;
  margin: 0 auto;
  padding-bottom: 2em;
}

.logo {
  display: inline-block;
  min-width: 34px;
  height: 34px;
  background-image: url('/assets/heart.svg');
  background-repeat: no-repeat;
  background-size: contain;
  background-position-y: center;
  flex: 1;
  
  @media only screen and (min-width: 1280px) {
    min-width: 224px;
    height: 36px;
    background-image: url('/assets/btd-type-logo.svg');
  }

  @media only screen and (min-width: 1368px) {
    min-width: 300px;
    background-image: url('/assets/partner-portal-logo.svg');
  }
}

.menuButtons {
  display: flex;
  align-items: center;
  flex: 1;
  justify-content: flex-end;

  button {
    height: 37px;

    >span {
      width: 20px !important;
      height: 20px !important;
    }

    &:first-child {
      margin-right: 1em;
    }
  }
}