.wrapper {
  border-radius: 8px;
  background-color: #FFF6E7;
  padding: 1rem;
  font-size: var(--font-14);
  margin-bottom: 1rem;
  
  h3 {
    font-size: var(--font-14);
    font-weight: 700;
    margin: 0;
    margin-bottom: 5px;
  }

  ul {
    list-style: disc;
    margin-left: 1em;
  
    li {
      margin-bottom: 5px;
    }
  }
}

.narrow {
  padding: 12px;
}