.message {
  animation-name:
    slidedown,
    fadeout;

  animation-delay:
    0s,
    var(--fadeoutDelay);

  animation-duration:
    0.4s,
    var(--animationDuration);

  animation-iteration-count: 1;

  animation-fill-mode: forwards;
}

@keyframes fadeout {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes slidedown {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0%);
  }
}