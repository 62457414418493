.table-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 18px;

  a {
    font-size: var(--font-14);
  }

  div {
    display: flex;
    align-items: center;

    &:last-child {
      margin-left: auto;
    }

    & > svg {
      width: 22px;
      height: 16px;
      object-fit: contain;
      margin-right: 5px;
    }
  }
}

.column {
  overflow-x: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  
  &.name, &.email {
    width: 30%;
  }
  
  &.services {
    width: 40%;
  }
}