.skeleton {
  background-color: #d8d8d8;
  animation: 2s infinite pulse;
  color: #d8d8d8;
  margin-bottom: 5px;
  background-image: linear-gradient(90deg,
                  rgba(66, 66, 66, 0) 33%,
                  rgba(255, 255, 255, 0.2) 50%,
                  rgba(66, 66, 66, 0) 66%);
  background-size: 300% 100%;
  animation: shine 2s infinite;
  width: 100%;
  border-radius: var(--border-radius);
  opacity: 1;
}

@keyframes shine {
  0% {
    background-position: right;
  }
  50% {
    opacity: 0.8;
  }
}