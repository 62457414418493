:root {
  /* colors */
  --white: hsl(0, 0%, 100%);
  --brand-main: hsl(331, 92%, 23%);
  --primary-color: hsl(333, 81%, 36%);
  --secondary-color: hsl(332, 100%, 95%);
  --primary-text-color: hsl(225, 6%, 14%);
  --secondary-text-color: hsl(0, 0%, 65%);
  --input-border-color: hsl(0, 0%, 80%);
  --primary-disabled-color: hsl(0, 0%, 65%);
  --primary-hover-color: hsl(333, 82%, 30%);
  --primary-active-color: hsl(333, 81%, 25%);
  --secondary-hover-color: hsl(333, 47%, 96%);
  --secondary-active-color: hsl(334, 47%, 90%);
  --button-focus-halo-color: hsl(334, 47%, 90%);
  --disabled-input-background-color: hsl(0, 0%, 96%);
  --error-color: hsl(357, 76%, 49%);
  --error-background-color: hsl(5, 86%, 97%);
  --success-color: hsl(155, 97%, 24%);
  --success-background-color: hsl(145, 81%, 96%);
  --accumbo-primary: hsl(331, 92%, 23%);
  --accumbo-secondary: hsl(342, 63%, 95%);

  --primary-50: hsl(336, 83%, 98%);
  --primary-100: hsl(334, 80%, 96%);
  --primary-200: hsl(333, 83%, 91%);
  --primary-300: hsl(333, 82%, 85%);
  --primary-400: hsl(333, 82%, 45%);
  --primary-500: hsl(333, 81%, 40%);
  --primary-600: hsl(333, 81%, 36%);
  --primary-700: hsl(333, 82%, 30%);
  --primary-800: hsl(333, 82%, 21%);

  --grey-25: hsl(0, 0%, 99%);
  --grey-50: hsl(0, 0%, 98%);
  --grey-100:hsl(0, 0%, 95%);
  --grey-200:hsl(0, 0%, 91%);
  --grey-300:hsl(0, 0%, 84%);
  --grey-400:hsl(0, 0%, 74%);
  --grey-500:hsl(0, 0%, 61%);
  --grey-600:hsl(0, 0%, 49%);
  --grey-700:hsl(0, 0%, 37%);
  --grey-800:hsl(0, 0%, 26%);
  --grey-900:hsl(0, 0%, 13%);

  --negative-25: hsl(12, 100%, 99%);
  --negative-50: hsl(5, 86%, 97%);
  --negative-100: hsl(4, 93%, 94%);
  --negative-200: hsl(3, 96%, 89%);
  --negative-300: hsl(4, 96%, 80%);
  --negative-400: hsl(4, 92%, 69%);
  --negative-500: hsl(4, 86%, 58%);
  --negative-600: hsl(4, 74%, 49%);
  --negative-700: hsl(4, 76%, 40%);
  --negative-800: hsl(4, 72%, 33%);
  --negative-900: hsl(8, 65%, 29%);

  --positive-25: hsl(142, 80%, 98%);
  --positive-50: hsl(145, 81%, 96%);
  --positive-100: hsl(140, 80%, 90%);
  --positive-200: hsl(144, 78%, 80%);
  --positive-300: hsl(148, 74%, 67%);
  --positive-400: hsl(150, 66%, 52%);
  --positive-500: hsl(152, 82%, 39%);
  --positive-600: hsl(153, 96%, 30%);
  --positive-700: hsl(155, 97%, 24%);
  --positive-800: hsl(155, 90%, 20%);
  --positive-900: hsl(156, 88%, 16%);

  --critical-25: hsl(42, 100%, 98%);
  --critical-50: hsl(45, 100%, 96%);
  --critical-100: hsl(45, 96%, 89%);
  --critical-200: hsl(44, 98%, 77%);
  --critical-300: hsl(42, 99%, 65%);
  --critical-400: hsl(39, 98%, 56%);
  --critical-500: hsl(34, 94%, 50%);
  --critical-600: hsl(28, 97%, 44%);
  --critical-700: hsl(22, 92%, 37%);
  --critical-800: hsl(19, 84%, 31%);
  --critical-900: hsl(18, 79%, 27%);

  --blue-25: #F5FAFF;
  --blue-50: #EFF8FF;
  --blue-100: #D1E9FF;
  --blue-200: #B2DDFF;
  --blue-300: #84CAFF;
  --blue-400: #53B1FD;
  --blue-500: #2E90FA;
  --blue-600: #1570EF;
  --blue-700: #175CD3;
  --blue-800: #1849A9;
  --blue-900: #194185;

  --slate-25: #FCFCFD;
  --slate-50: #F8F9FC;
  --slate-100: #EAECF5;
  --slate-200: #D5D9EB;
  --slate-300: #AFB5D9;
  --slate-400: #717BBC;
  --slate-500: #4E5BA6;
  --slate-600: #3E4784;
  --slate-700: #363F72;
  --slate-800: #293056;
  --slate-900: #1D223D;


  --selected-step-color: hsl(0, 0%, 100%);
  --selected-step-background: hsl(216, 80%, 35%);
  --selected-step-background-pulse: hsl(216, 100%, 55%);
  --selected-step-border-color: hsl(216, 79%, 30%);
  --step-color: hsl(216, 80%, 35%);
  --step-background: hsl(0, 0%, 100%);
  --step-border-color: hsl(229, 35%, 88%);


  /* typography */
  --body-font: 'Inter', sans-serif;
  --heading-font: 'IsidoraSans-SemiBold', 'Inter', sans-serif;
  --font-8: 0.5rem;
  --font-10: 0.625rem;
  --font-12: 0.75rem;
  --font-13: 0.8125rem;
  --font-14: 0.875rem;
  --font-16: 1rem;
  --font-18: 1.125rem;
  --font-20: 1.25rem;
  --font-22: 1.375rem;
  --font-26: 1.625rem;
  --font-32: 2rem;

  /* other */
  --border-radius: 6px;
  --border-radius-sm: 3px;
  --border-radius-lg: 8px;
}
