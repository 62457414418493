.steps {
  border: 1px solid var(--grey-200);
  padding: 16px;
  border-radius: 8px;

  h4 {
    font-size: var(--font-14);
    font-weight: 500;
    margin: 0;
  }

  ol {
    counter-reset: steps-counter;
    list-style: none;
    padding-left: 2rem;
    margin-top: 1rem;
    margin-bottom: 1rem;

    li {
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: relative;
      counter-increment: steps-counter;
      color: var(--grey-900);
      font-size: var(--font-14);
      font-weight: 600;
      white-space: nowrap;

      span:first-child {
        margin-right: 15px;
      }

      &::before {
        --size: 20px;
        content: counter(steps-counter);
        position: absolute;
        text-align: center;
        width: var(--size);
        height: var(--size);
        color: var(--accumbo-primary);
        background-color: var(--accumbo-secondary);
        font-size: var(--font-13);
        line-height: var(--size);
        font-weight: 600;
        left: calc(-1 * var(--size) - 12px);
        top: 1px;
        border-radius: 50%;
      }
    }
  }
}

.currentAuscultationStatus {
  display: block;
  color: var(--grey-600);
  font-weight: 400;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  button {
    margin-right: 5px;
  }
}

.auscultationStepCompleted {
  background-image: url('../../../assets/icons/checkmark.svg');
  background-repeat: no-repeat;
  background-position: 100% center;
  background-size: 20px 20px;
  padding-right: 25px;
  width: max-content;
  color: var(--positive-600);
}

.onboardingPending {
  background-image: url('../../../assets/icons/verified.svg');
  background-repeat: no-repeat;
  background-position: 16px center;
  background-color: var(--slate-50);
  border: 1px solid var(--slate-100);
  padding: 16px;
  padding-left: 52px;
  border-radius: 8px;
  margin-bottom: 2em;
  font-size: var(--font-14);
}