.patientInfo {
  display: flex;
  align-items: center;
  font-size: var(--font-14);
  margin-top: 12px;

  & > div {
    display: flex;
    align-items: center;
  }
}

.patientAvatar {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  font-size: var(--font-16);
  margin-right: 8px;
  
  &.empty {
    background-color: var(--grey-100);
  }
  
  &.male {
    background-color: var(--blue-50);
    color: var(--blue-500);
  }
  
  &.female {
    background-color: var(--slate-100);
    color: var(--slate-500);
  }
}

.skeleton {
  background-color: var(--grey-100);
  display: inline-block;
  height: 17px;

  &:first-child {
    margin-right: 8px;
  }
}

.patientName {
  font-weight: 600;
  margin-right: 4px;
}

.patientGenderAge {
  font-weight: 400;
  color: var(--grey-700);
}

