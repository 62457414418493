.loginForm {
  max-width: 300px;

  .loginStarted {
    text-align: center;
  }

  header {
    text-align: center;
    margin-bottom: 2em;
    
    &.bg {
      background-color: var(--white);
    }
  }

  img {
    width: 270px;
    display: inline;
  }

  h1:focus {
    outline: none;
  }

  form {
    width: 300px;

    input,
    label {
      width: 100%;
      display: block;
    }
  }

  label {
    margin-bottom: 10px;
  }

  button {
    margin-top: 15px;
  }
}

.logoutMessage {
  font-size: var(--font-14);
  text-align: center;
  margin-top: 10px;
}
