.wrapper {
  border: 1px solid #E7E7E7;
  padding: 10px;
  border-radius: 6px;
  overflow: hidden;
  transition: box-shadow 0.2s ease;

  button > svg {
    margin-right: 10px;
  }
}

.active {
  box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.04), 0px 4px 4px rgba(0, 0, 0, 0.04);
}

.audioRow {
  display: flex;
  padding-top: 12px;

  canvas {
    height: 70px;
  }

  button {
    min-width: 115px;
    justify-content: center;
    align-self: auto;
  }
}

.actions {
  display: flex;
  gap: 1rem;
  padding-top: 1rem;

  > button {
    flex: 1;
    height: 43px;
  }

  button {
    justify-content: center;
    
    &:last-child {
      max-width: calc(50% - 1rem);
    }
  }
}

.player {
  display: none;
}

.canvasWrapper {
  position: relative;
  width: 100%;
  margin-right: 12px;
}

.progress {
  position: absolute;
  width: 0%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 6px 0 0 6px;
}

.playing {
  animation: scan var(--progressBarDuration) linear;
}

.finished {
  width: 100%;
  animation: fade 1s ease forwards;
}

.recordingHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.orderNumber {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: var(--step-background);
  border: 2px solid var(--step-border-color);
  color: var(--step-color);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1), 0px 3px 7px rgba(0, 0, 0, 0.1);
  margin-right: 8px;
  
  &.active {
    background-color: var(--selected-step-background);
    border: 2px solid var(--selected-step-border-color);
    color: var(--selected-step-color);
  }
}

.noRecording {
  color: var(--grey-600);
}

@keyframes scan {
  0% {
    width: 10px;
  }

  100% {
    width: 100%;
  }
}

@keyframes fade {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
