.wrapper {
  max-width: 350px;
  margin: 0 auto;
  margin-top: 1rem;
  height: 260px;

  h4 {
    font-weight: 500;
    margin-top: 1rem;
  }
}

.canvasWrapper {
  margin-bottom: 30px;

  canvas {
    padding: 0;
    border-radius: 4px;
    height: 32px;
  }
}