.space-between {
  display: flex;
  justify-content: space-between;
}

.align-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.smallcaps {
  font-variant: small-caps;
  font-size: var(--font-18);
}

.mt-1 {
  margin-top: 1em;
}

.mt-2 {
  margin-top: 2em;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mb-1 {
  margin-bottom: 1em;
}

.mb-2 {
  margin-bottom: 2em;
}

.pointer {
  cursor: pointer;
}

.flex {
  display: flex;
}

.gap-1 {
  gap: 1em;
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.fs-14 {
  font-size: var(--font-14);
}

.padded-content {
  margin-left: 50px;
  margin-right: 50px;
}

.disc-list {
  list-style: disc;
  margin-left: 1em;

  li:not(:last-child) {
    margin-bottom: 0.5em;
  }
}

.border-radius-0 {
  border-radius: 0 !important;
}

.w-10 {width: 10%;}
.w-20 {width: 20%;}
.w-25 {width: 25%;}
.w-30 {width: 30%;}
.w-40 {width: 40%;}
.w-50 {width: 50%;}
.w-60 {width: 60%;}
.w-70 {width: 70%;}
.w-80 {width: 80%;}
.w-90 {width: 90%;}
.w-100 {width: 100%;}

.stack-0 {
  flex: 1;

  & > *:not(:last-child) {
    margin-bottom: 5px;
  }
}

.stack-1 {
  flex: 1;

  & > *:not(:last-child) {
    margin-bottom: 1em;
  }
}

.stack-2 {
  flex: 1;

  & > *:not(:last-child) {
    margin-bottom: 2em;
  }
}

.stack-3 {
  flex: 1;

  & > *:not(:last-child) {
    margin-bottom: 3em;
  }
}

.ml-auto {margin-left: auto;}
.mr-auto {margin-right: auto;}

.strike {
  text-decoration: line-through;
}

.rotated {
  transform: rotateZ(180deg);
}
.hidden {
  display: none;
}