.wrapper {
  z-index: 1;
  position: relative;
}

.button {
  cursor: pointer;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  border: 1px solid var(--input-border-color);
  background-color: var(--white);
  padding: 12px;
  border-radius: 6px;

  .buttonText {
    text-align: left;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: calc(100% - 30px);
  }

  .buttonIcon {
    width: 30px;
    text-align: right;
  }
}

.options {
  position: absolute;
  border: 1px solid var(--input-border-color);
  background-color: var(--white);
  margin-top: 5px;
  border-radius: 6px;
  width: 100%;
}

.option {
  cursor: pointer;
  padding: 12px;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  &:hover {
    background-color: var(--secondary-hover-color);
  }
}

.optionSelected {
  font-weight: 600;
}

.optionActive {
  background-color: var(--secondary-hover-color);
}

.newItem {
  color: var(--primary-400);
  font-weight: bold;
  margin-right: 8px;
}
