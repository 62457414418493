.completed {
  background-image: url('../../../assets/icons/checkmark.svg');
  background-repeat: no-repeat;
  background-position: 100% center;
  background-size: 20px 20px;
  padding-right: 25px;
  color: var(--positive-600);
}

.expired {
  color: var(--negative-500);
}