input,
button,
select,
textarea {
  font-size: inherit;
  font-family: inherit;
  padding: 0;
  margin: 0;
}

fieldset {
  border: none;
  padding: 0;
  margin: 0;

  legend {
    font-size: var(--font-16);
    font-weight: 600;
    margin-bottom: 14px;
  }

  label {
    display: block;
  }
}

.pseudo-label,
label {
  display: inline-block;
  margin-bottom: 5px;
  font-weight: 500;
  font-size: var(--font-14);

  + .error-message {
    margin-left: 5px;
  }

  small {
    display: block;
    color: #7C7C7C;
    font-size: var(--font-12);
  }
}

.flex-label {
  position: relative;
  display: flex;
  align-items: flex-start;

  input {
    position: relative;
    top: 4px;
  }

  div {
    flex: 1;
  }

  &:not(:last-child) {
    margin-bottom: 1em;
  }
}

.pseudo-label > div {
  overflow-x: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

input:disabled,
.fake-input.disabled {
  background-color: var(--disabled-input-background-color);
}

input,
.fake-input {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 12px;
  height: 40px;
  border: 1px solid var(--input-border-color);
  border-radius: var(--border-radius);

  &.error {
    border-color: var(--error-color);
  }
}

.fake-input {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

input[type='file'] {
  height: 0;
  width: 0;
  overflow: hidden;
  border: 0;
  padding: 0;

  + label {
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    font-size: var(--font-14);
    font-weight: 600;
    color: var(--white);
    background-color: var(--primary-color);
    outline: none;
    border: none;
    border: var(--border);
    border-radius: var(--border-radius);
    padding: 8px 12px;
    margin-bottom: 0;
    margin-left: 8px;

    &:hover {
      background-color: var(--primary-hover-color);
    }

    &:active {
      background-color: var(--primary-active-color);
    }
  }
}

textarea {
  width: 100%;
  border: 1px solid var(--input-border-color);
  border-radius: var(--border-radius);
  padding: 6px 12px;
}

input[type='text'],
textarea {
  &:focus {
    outline: 1px solid var(--primary-color);
    box-shadow: 0px 0px 0px 1px var(--white), 0px 0px 0px 4px var(--button-focus-halo-color);
  }
}

input.large {
  padding: 14px 11px;
}

form {
  width: 100%;
  margin-bottom: 1rem;

  input[type='text'] {
    width: 100%;
  }

  > div,
  fieldset {
    margin-bottom: 30px;
  }

  textarea {
    min-height: 130px;
  }
}

.fake-input.icon,
input[type='text'].icon {
  background-position: calc(100% - 10px) center;
  background-repeat: no-repeat;

  &.loading {
    background-image: url('../assets/spinner.svg');
  }

  &.success {
    background-image: url('../assets/icons/success.svg');
  }

  &.error {
    background-image: url('../assets/icons/error.svg');
  }
}

input[type='radio'],
input[type='checkbox'] {
  display: inline-flex;
  align-items: center;
  height: auto;
  margin-right: 10px;
  accent-color: var(--brand-main);
}

input[type='radio'] {
  position: absolute;
  opacity: 0;
  visibility: visible;

  &:focus-visible {
    + label::before {
      outline: 2px solid #000000;
    }
  }

  &:checked + label {
    &::before {
      border: 5px solid var(--primary-color);
      background-color: var(--white);
      box-shadow: none;
    }
  }

  & + label {
    display: flex;
  }

  &:not(:checked) + label {
    &:hover {
      &::before {
        border: 1px solid var(--primary-500);
      }
    }
  }

  & + label::before {
    display: inline-block;
    position: relative;
    content: '';
    height: 18px;
    width: 18px;
    top: 2px;
    margin-right: 10px;
    background: var(--white);
    border: 1px solid #BCBCBC;
    box-shadow: inset 0px 1px 2px rgba(0, 0, 0, 0.2);
    border-radius: 50%;
  }

  &[disabled] + label::before {
    background: #cccccc;
    opacity: 0.6;
  }
}

input[type='checkbox'] {
  position: absolute;
  opacity: 0;
  visibility: visible;

  &:focus-visible {
    + label::before {
      outline: 2px solid #000000;
    }
  }

  &:checked + label {
    &::before {
      border: none;
      background-color: var(--primary-color);
      box-shadow: none;
      background-image: url('data:image/svg+xml;utf8,<svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M11.4571 1.95711C11.8476 1.56658 11.8476 0.933418 11.4571 0.542893C11.0666 0.152369 10.4334 0.152369 10.0429 0.542893L11.4571 1.95711ZM5 7L4.29289 7.70711C4.68342 8.09763 5.31658 8.09763 5.70711 7.70711L5 7ZM2.45711 3.04289C2.06658 2.65237 1.43342 2.65237 1.04289 3.04289C0.652369 3.43342 0.652369 4.06658 1.04289 4.45711L2.45711 3.04289ZM10.0429 0.542893L4.29289 6.29289L5.70711 7.70711L11.4571 1.95711L10.0429 0.542893ZM5.70711 6.29289L2.45711 3.04289L1.04289 4.45711L4.29289 7.70711L5.70711 6.29289Z" fill="white"/></svg>');
      background-repeat: no-repeat;
      background-position: center center;
    }
  }

  & + label {
    display: flex;
  }

  &:not(:checked):not([disabled]) + label {
    &:hover {
      &::before {
        border: 1px solid var(--primary-500);
      }
    }
  }

  & + label::before {
    display: inline-block;
    position: relative;
    content: '';
    height: 18px;
    width: 18px;
    top: 2px;
    margin-right: 10px;
    background: var(--white);
    border: 1px solid #BCBCBC;
    box-shadow: inset 0px 1px 2px rgba(0, 0, 0, 0.2);
    border-radius: 3px;
  }

  &[disabled] + label::before {
    background-color: #cccccc;
    opacity: 0.6;
  }
}