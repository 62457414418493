.wrapper {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.errorContent {
  max-width: 400px;
  max-height: 300px;
  overflow-y: auto;
  overflow-wrap: break-word;
  font-family: 'Courier New', Courier, monospace;
  font-size: var(--font-14);
}