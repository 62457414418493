.wrapper {
  display: inline-block;
  position: relative;

  & > span {
    cursor: help;
    line-height: 1;
  }

  .content {
    min-width: 300px;
    top: -10px;
    left: 50%;
    transform: translate(-50%, -100%);
    padding: 10px 20px;
    color: var(--white);
    background-color: var(--grey-900);
    font-weight: normal;
    font-size: var(--font-13);
    border-radius: 6px;
    position: absolute;
    z-index: 99999999;
    box-sizing: border-box;
    box-shadow: 0 1px 8px rgba(0,0,0,0.5);
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.2s;

    i {
      position: absolute;
      top: 100%;
      left: 50%;
      margin-left: -12px;
      width: 24px;
      height: 12px;
      overflow: hidden;

      &::after {
        content: '';
        position: absolute;
        width: 12px;
        height: 12px;
        left: 50%;
        transform: translate(-50%, -50%) rotate(45deg);
        background-color: var(--grey-900);
        box-shadow: 0 1px 8px rgba(0,0,0,0.5);
      }
    }
  }

  &:hover .content {
    visibility: visible;
    opacity: 1;
  } 
}
