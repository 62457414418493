.policy {
  margin-top: 5px;
  padding: 5px;
  background-color: var(--grey-50);
  border: 1px solid var(--grey-200);
  font-weight: 500;
  border-radius: 4px;
  width: fit-content;
}

.editLink {
  & > * {
    display: flex;
    align-items: center;
  }

  svg {
    width: 16px;
    height: 16px;
    object-fit: contain;
    margin-right: 7px;
  }
}

.active {
  color: var(--positive-600);
}

.inactive {
  color: var(--negative-500);
}