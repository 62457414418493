.playbackWrapper {
  display: flex;
  align-items: center;
}

.canvasWrapper {
  position: relative;
  width: 100%;
  height: 50px;

  canvas {
    height: 100%;
  }
}

.progress {
  position: absolute;
  width: 0%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 6px 0 0 6px;
}

.playing {
  animation: scan var(--progressBarDuration) linear;
}

.controls {
  width: 40px;
  align-items: flex-end;
}

.playButton {
  background-image: url('../../../assets/icons/play.svg');

  &:disabled {
    filter: grayscale(1);
    opacity: 0.6;
  }
}

.stopButton {
  background-image: url('../../../assets/icons/stop.svg');
}

@keyframes scan {
  0% {
    width: 10px;
  }

  100% {
    width: 100%;
  }
}