.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 20px;
  border: 1px solid var(--grey-200);
  border-radius: var(--border-radius-lg);
  transition: all 0.2s ease-out;
  
  &:hover {
    box-shadow: 0px 5px 19px -7px rgba(0,0,0,0.1);
  }
  
  & > a {
    text-decoration: none;
    flex: 1;

    &:hover {
      text-decoration: none;
    }
  }
}

.itemHeader {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 22px 24px;
  
  & > div:first-child {
    margin-right: 20px;
  }
  
  h2 {
    font-size: var(--font-20);
    font-weight: 600;
    margin-top: 0;
  }
  
  p {
    font-size: var(--font-14);
    color: var(--grey-800);
    font-weight: 400;
  }
}

.actionWrapper {
  a:hover {
    text-decoration: none;
  }
}

.actionLink {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 56px;
  padding: 10px 20px;
  line-height: 1;
  border-top: 1px solid var(--grey-200);
  font-size: var(--font-14);

  &:last-child {
    border-radius: 0 0 var(--border-radius-lg) var(--border-radius-lg);
  }

  &:hover {
    background-color: var(--grey-25);
  }

  & > svg {
    width: 20px;
    height: 20px;
    object-fit: cover;
    fill: #B8135D;
  }
}

.disabled {
  pointer-events: none;

  & > span {
    color: var(--grey-500);
    cursor: default;
  }

  svg {
    fill: var(--grey-500);
  }
}

.notice {
  margin: 0 24px 24px 24px;
  padding: 16px;
  padding-left: 52px;
  border-radius: 8px;
  
  background-repeat: no-repeat;
  background-size: 20px 24px;
  background-position: 18px 16px;
  
  font-size: var(--font-14);
  color: var(--grey-800);
  font-weight: 400;
  
  p:first-child {
    margin-bottom: 5px;
  }
  
  &.initial {
    background-image: url('../../../../assets/icons/notice.svg');
    background-color: var(--critical-50);
    border: 1px solid var(--critical-100);
  }
  
  &.pending {
    background-image: url('../../../../assets/icons/verified.svg');
    background-color: var(--slate-50);
    border: 1px solid var(--slate-100);
  }
  
  &.rejected {
    background-image: url('../../../../assets/icons/denied.svg');
    background-color: var(--negative-50);
    border: 1px solid var(--negative-100);
  }

  blockquote {
    padding: 8px;
    background: var(--white);
    border-radius: 4px;
    margin: 10px auto;
  }
}
