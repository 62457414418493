@import '../../../styles/mixins';

.navBar {
  width: 100%;
  padding: 24px 19px;
  
  @include respond(desktop) {
    height: 80px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .logo {
    display: inline-block;
    width: 300px;
    height: 36px;
    background-image: url('/assets/partner-portal-logo.svg');
    background-repeat: no-repeat;
    background-size: contain;
    background-position-y: center;
  }
}

.loggedIn {
  display: flex;

  & > *:not(:last-child) {
    margin-right: 2em;
  }
}