@import './_reset.scss';
@import './_mixins.scss';
@import './forms.scss';
@import './values.scss';
@import './helpers.scss';
@import './table.scss';

@font-face {
  font-family: 'IsidoraSans-Bold';
  src: url('../assets/fonts/34C90D_7_0.eot'), url('../assets/fonts/34C90D_7_0.eot?#iefix') format('embedded-opentype'),
    url('../assets/fonts/34C90D_7_0.woff2') format('woff2'), url('../assets/fonts/34C90D_7_0.woff') format('woff'),
    url('../assets/fonts/34C90D_7_0.ttf') format('truetype');
}
@font-face {
  font-family: 'IsidoraSans-Regular';
  src: url('../assets/fonts/34C90D_12_0.eot'), url('../assets/fonts/34C90D_12_0.eot?#iefix') format('embedded-opentype'),
    url('../assets/fonts/34C90D_12_0.woff2') format('woff2'), url('../assets/fonts/34C90D_12_0.woff') format('woff'),
    url('../assets/fonts/34C90D_12_0.ttf') format('truetype');
}
@font-face {
  font-family: 'IsidoraSans-SemiBold';
  src: url('../assets/fonts/34C90D_16_0.eot'), url('../assets/fonts/34C90D_16_0.eot?#iefix') format('embedded-opentype'),
    url('../assets/fonts/34C90D_16_0.woff2') format('woff2'), url('../assets/fonts/34C90D_16_0.woff') format('woff'),
    url('../assets/fonts/34C90D_16_0.ttf') format('truetype');
}

::placeholder {
  color: var(--secondary-text-color);
}

html,
body {
  position: relative;
  background: var(--white);
}

main h1:first-child {
  margin-top: 0;

  & + p {
    color: var(--grey-700);
    font-weight: 400;
    font-size: var(--font-14);
  }
}

@include respond(desktop) {
  main h1:first-child {
    margin-top: 0;
    margin-bottom: 8px;
  }
}

body {
  color: var(--primary-text-color);
  margin: 0;
  font-family: var(--body-font);
  font-size: var(--font-14);
}

a, .fake-link {
  color: var(--primary-color);
  text-decoration: none;
  font-weight: 600;

  &:hover {
    text-decoration: underline;
  }
}

ul {
  list-style-type: none;
  padding-inline-start: 0;
}

ol {
  margin: 2rem 0;
  padding-inline-start: 1.2rem;

  li:not(:last-child) {
    margin-bottom: 0.8rem;
  }
}

p:not(:last-child) {
  margin-bottom: 1.25em;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--heading-font);
  margin: 0.5em 0;
  color: var(--grey-900);
}

h1 {
  font-size: var(--font-26);
}

h2 {
  font-size: var(--font-22);
}

.error-message {
  font-size: var(--font-12);
  color: var(--error-color);
}

.info-message {
  font-size: var(--font-12);
  color: var(--secondary-text-color);
}

.narrow-page {
  margin: 0 auto;
  max-width: 440px;
}

.stack > *:not(:last-child) {
  margin-bottom: 1rem;
}

.education {
  h3 {
    font-size: var(--font-14);
  }
}

kbd {
  display: inline-block;
  color: var(--grey-800);
  background-color: var(--grey-100);
  border-radius: 3px;
  border: 1px solid var(--grey-400);
  font-size: var(--font-12);
  font-weight: 700;
  line-height: 1;
  padding: 2px 4px;
  white-space: nowrap;
}

.heading-count {
  color: var(--grey-500);
  margin-left: 5px;
}