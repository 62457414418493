@import '../../styles/mixins';

.profileCard {
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: var(--font-12);
  color: var(--grey-700);
}

.avatar {
  width: 40px;
  height: 40px;
  background-image: url('../../assets/avatar.svg');
  background-size: contain;
  background-repeat: no-repeat;
  margin-right: 10px;
}

.username {
  display: inline-flex;
  align-items: center;
  width: 100%;
  font-size: var(--font-14);
  font-weight: 600;
  color: var(--grey-900);
}

