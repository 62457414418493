.wrapper {
  position: relative;
}

.panel {
  position: absolute;
  right: 0;
  margin-top: 10px;
  background-color: var(--white);
  border: 1px solid var(--grey-200);
  border-radius: var(--border-radius-lg);
  box-shadow: 0px 5px 19px -7px rgba(0,0,0,0.1);
}

.trigger {
  outline: none;
  border: none;
  background-color: unset;
  cursor: pointer;
}