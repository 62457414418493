.description {
  margin-left: 22px;
}

.fileSelect {
  display: flex;
  justify-content: space-between;

  div {
    flex: 1;
  }
}
