.wrapper {
  width: 354px;
  margin: 0 auto;

  header {
    text-align: center;
    margin-bottom: 40px;
  }
}

.noEcgNotice {
  font-size: var(--font-14);
  background-color: var(--grey-50);
  padding: 10px;
  border-radius: var(--border-radius);
}