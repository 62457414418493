.recordingWrapper {
  display: flex;
  justify-content: center;

  > div {
    width: 50%;

    &:first-child {
      margin-right: 2em;
    }
  }
}

.auscultationHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  
  h2 {
    font-weight: 700;
    font-size: var(--font-26);
    margin: 0;
  }
}

.instructionsList {
  list-style: disc;
  margin-left: 1em;

  li {
    margin-bottom: 1em;
  }
}

.nextButtonWrapper {
  display: flex;
  justify-content: flex-end;
  margin-top: 1em;
}