.wrapper {
  max-width: 350px;
  margin: 0 auto;
  margin-top: 1rem;

  h4 {
    font-weight: 500;
    margin-top: 1rem;
  }
}

.inputRow {
  cursor: pointer;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding: 8px 17px; 
}

.deviceSelected {
  font-weight: 600;
  background-color: var(--primary-100);
}

.newDevice {
  color: var(--primary-500);
  font-weight: bold;
  margin-right: 8px;
}

.deviceListWrapper {
  border: 1px solid var(--grey-200);
  border-radius: var(--border-radius);
  margin-bottom: 1em;
}