.wrapper {
  max-width: 480px;
  margin: 0 auto;
  font-size: var(--font-14);

  small {
    display: flex;
    justify-content: center;
  }
}

.displayInModal {
  max-width: unset;
  margin: unset;

  .actions {
    padding: 0 16px 16px 16px;
  }
}

.stepWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;

  img, svg {
    border-radius: 8px;
    width: 100%;
  }

  ol {
    max-width: 350px;
    margin-bottom: 0;
  }

  h2 {
    margin-bottom: 2rem;
  }
}

.actions {
  display: flex;
  margin-top: 40px;
  
  button {
    max-width: 50%;
    
    &:last-child {
      margin-left: auto;
    }

    &:not(:last-child) {
      margin-right: 12px;
    }
  }
}