.wrapper {
  border: 1px solid var(--grey-200);
  border-radius: var(--border-radius-lg);
}

.row {
  & > * {
    display: flex;
    font-size: var(--font-14);
    padding: 12px 20px;
  }
    
  &:not(:last-child) {
    border-bottom: 1px solid var(--grey-200);
  }
  
  &:last-child {
    border-radius: 0 0 var(--border-radius-lg) var(--border-radius-lg);
  }
  
  a {
    color: var(--primary-text-color);
    text-decoration: none;
    font-weight: normal;
    
    &:hover {
      text-decoration: none;
    }
  }

  
  &:hover {
    background-color: var(--grey-25);
  }
}

.columnHeaders {
  border-radius: var(--border-radius-lg) var(--border-radius-lg) 0 0;
  display: flex;
  font-weight: 500;
  background-color: var(--grey-50);
  color: var(--grey-600);
  font-size: var(--font-12);
  padding: 8px 20px;
}

.fallback {
  padding: 12px 20px;
}