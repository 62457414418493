.wrapper {
  header {
    display: flex;
    justify-content: center;
  }

  h2 {
    font-size: var(--font-32);
    margin: 0;
    margin-bottom: 40px;
  }

  form {
    margin-top: 45px;
  }

  textarea {
    margin-bottom: 30px;
  }
}

.columns {
  display: flex;

  h3 {
    margin: 0;
    margin-bottom: 30px;
  }

  > div {
    flex: 1;    
  }
  
  > div:not(:last-child) {
    margin-right: 32px;
  }
}

.modalContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  img {
    width: 90px;
    height: 90px;
    margin-bottom: 2rem;
  }

  p {
    font-weight: 700;
    font-size: var(--font-18);
  }
}

.uploadedFile {
  width: 200px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.successIcon {
  background-image: url('../../../assets/icons/success.svg');
  background-repeat: no-repeat;
  background-position: 100% center;
  padding-right: 20px;
  font-style: italic;
}