.wrapper {
  position: relative;
  width: 460px;
}

.point {
  position: absolute;
  user-select: none;
  width: 46px;
  height: 46px;
  border-radius: 50%;
  font-weight: 600;
  font-size: var(--font-22);
  color: var(--step-color);
  background-color: var(--step-background);
  border: 2px solid var(--step-border-color);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
}

.heartTopRight {
  top: 203px;
  left: 161px;
}

.heartTopLeft {
  top: 203px;
  left: 251px;
}

.heartBottomRight {
  top: 281px;
  left: 243px;
}

.heartBottomLeft {
  top: 336px;
  left: 274px;
}

.lungsTopRight {
  top: 78px;
  left: 334px;
}

.lungsTopLeft {
  top: 78px;
  left: 80px;
}

.lungsMidRight {
  top: 194px;
  left: 295px;
}

.lungsMidLeft {
  top: 194px;
  left: 120px;
}

.lungsBottomRight {
  top: 321px;
  left: 295px;
}

.lungsBottomLeft {
  top: 321px;
  left: 120px;
}

.selected {
  color: var(--selected-step-color);
  border: 2px solid var(--selected-step-border-color);
  background-color: var(--selected-step-background);

  &.animateActive::before {
    position: absolute;
    content: '';
    width: 100%;
    height: 100%;
    animation: pulse 0.7s ease-out infinite alternate;
    background-color: var(--selected-step-background);
    border-radius: 50%;
    opacity: 0.5;
    z-index: -1;
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(1.5);
  }
}