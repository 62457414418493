.wrapper {
  position: relative;
  --modal-content-padding: 16px;
}

.backdrop {
  position: fixed;
  inset: 0;
  backdrop-filter: blur(4px);
}

.panelWrapper {
  position: fixed;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.panel {
  background-color: var(--white);
  box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03);
  border-radius: 20px;
  border-top: 1px solid var(--grey-200);
  overflow: hidden;

  header {
    min-height: 65px;
    position: relative;
    display: flex;
    padding: var(--modal-content-padding);
    justify-content: center;
    align-items: center;

    * {
      margin-top: 0;
      margin-bottom: 0;
    }

    button:hover {
      text-decoration: none !important;
    }
  }

  h2 {
    font-weight: normal;
  }
}

.action {
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
  padding: var(--modal-content-padding);

  button {
    width: 100%;
    display: inline-flex;
    justify-content: center;

    &:not(:last-child) {
      margin-right: 1em;
    }
  }
}

.hint {
  padding: var(--modal-content-padding);
}

.small {
  width: 300px;
}

.medium {
  width: 450px;
}

.large {
  width: 480px;
}

.xlarge {
  width: 700px;
  
  .childWrapper {
    max-height: 70vh;
    overflow-y: auto;
  }
}

.xxlarge {
  width: 90vw;
  max-width: calc(900px + 10px + var(--modal-content-padding)*2);
  
  .childWrapper {
    max-height: 70vh;
    overflow-y: auto;
  }
}

.closeButton {
  border: 0;
  background-color: transparent;
  cursor: pointer;
  display: block;
  width: 32px;
  height: 32px;
  border-radius: 4px;
  background-image: url('../../assets/icons/cross.svg');
  background-repeat: no-repeat;
  background-size: 14px 14px;
  background-position: center;

  &:hover {
    background-color: var(--grey-100);
  }

  position: absolute;
  left: var(--modal-content-padding);
}

.headerAction {
  position: absolute;
  right: var(--modal-content-padding);
}

.childWrapper {
  padding: var(--modal-content-padding);
}

.noPadding {
  padding: 0;
}

.headerBorder {
  border-bottom: 1px solid var(--grey-200);
}