@import '../../styles/mixins';

.mainContainer {
  min-height: 100vh;
}

.mainContainer,
.pageContent {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
}

.pageContent {
  padding: 0 10px;
}

.pageContent main {
  flex: 1 1 auto;
  padding-bottom: 2em;
}

@include respond(desktop) {
  .pageContent {
    width: 90%;
    max-width: 900px;
    margin: 0 auto;
    flex-direction: row;
    padding: 0;

    main {
      padding-top: 50px;

    }
  }

  .sidebar {
    flex: 0 0 260px;
  }
}

canvas {
  border-radius: 8px;
  padding: 10px;
  background-color: #F9F9F9;
  width: 100%;
}

.backLink {
  margin-bottom: 30px;
  font-size: var(--font-14);
  font-weight: 600;
  margin-bottom: 32px !important;
  
  a {
    color: var(--primary-500);
  }
}