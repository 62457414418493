.wrapper {
  width: 100%;
  max-width: 900px;
  margin: 0 auto;

  header {
    display: flex;
    justify-content: space-between;
  }

  .flashMessage {
    margin-top: 2em;
    width: max-content;
  }
}