.wrapper {
  position: relative;
  font-weight: 600;
  height: 56px;

  margin-bottom: 38px;

  &:hover {
    .editButton {
      display: inline;
    }
  }

  button {
    cursor: pointer;
    text-indent: -99999px;
    width: 30px;
    height: 30px;
    border: 0;
    background-color: transparent;
    background-size: contain;
  }
}




.editButton {
  display: none;
  text-indent: -99999px;
  background-image: url('../../../../assets/icons/pen.svg');
  background-repeat: no-repeat;
  background-color: transparent;
  background-size: contain;
  width: 18px;
  height: 18px;
  border: 0;
  margin-left: 1em;
}

.header {
  display: flex;
  margin-bottom: 5px;
}

.recordingMissing {
  font-weight: normal;
  font-size: var(--font-16);
  height: 50px;
  display: inline-flex;
  align-items: center;
}
