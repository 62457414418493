.statusMessage {
  font-weight: 500;
  font-size: var(--font-14);
  border-radius: var(--border-radius-sm);
  padding: 13px;
  padding-left: 42px;
  background-position: 16px center;
  background-repeat: no-repeat;
  width: fit-content;
}

.success {
  color: var(--success-color);
  background-color: var(--success-background-color);
  background-image: url('../../assets/icons/success.svg');
}

.error {
  color: var(--error-color);
  background-color: var(--error-background-color);
  background-image: url('../../assets/icons/error.svg');
}
